;(function($) {

  'use strict';

  /**
   * キーボード操作やWAI-ARIAに対応したアコーディオンです。
   * role属性とaria-*属性、tabindex属性はJS側で自動的に付与されます。
   * @see https://www.w3.org/TR/wai-aria-practices-1.1/examples/accordion/accordion.html
   * @author Manabu Yasuda
   * @param {jQuery object} tabs ['.js-accordion-tab'] - タブに指定するクラス属性値。
   * @param {jQuery object} tabpanels ['.js-accordion-panel'] - タブパネルに指定するクラス属性値。
   * @param {boolean} useRole [false] - role属性を付与する場合は`true`。
   * @param {boolean} openFirstChild [true] - デフォルトで最初の要素を開く場合は`true`。
   * @param {boolean} multiselectable [true] - 同時に複数の要素を開く場合は`true`。
   * @param {String || null} tabClass ['is-active'] - アクティブなタブに指定するクラス属性値。
   * @param {String || null} panelClass ['is-active'] - アクティブなタブパネルに指定するクラス属性値。
   * @param {function} beforeSetting(parent) - 初期設定を開始する前のコールバック関数です。
   * @param {function} afterSetting(parent) - 初期設定を終了した後のコールバック関数です。
   * @param {function} beforeClick(event, target) - クリックイベント処理前のコールバック関数です。
   * @param {function} afterClick(event, target) - クリックイベント処理後のコールバック関数です。
   * @param {function} beforeKeyEvent(event, target, index) - キーボードイベント処理前のコールバック関数です。
   * @param {function} afterKeyEvent(event, target, index) - キーボードイベント処理後のコールバック関数です。
   * @example
   * JS:
   * $('.js-accordion').accordion({
   *   'tabs': '.js-accordion-tab',
   *   'tabpanels': '.js-accordion-panel',
   *   'useRole': false,
   *   'openFirstChild': true,
   *   'multiselectable': true,
   *   'tabClass': 'is-active',
   *   'panelClass': 'is-active'
   * });
   *
   * HTML:
   * <dl class="js-accordion">
   *   <dt>
   *     <button class="js-accordion-tab" type="button">アコーディオン1-1</button>
   *   </dt>
   *   <dd class="js-accordion-panel">
   *     <p>パネル1-1</p>
   *   </dd>
   *   <dt>
   *     <button class="js-accordion-tab" type="button">アコーディオン1-2</button>
   *   </dt>
   *   <dd class="js-accordion-panel">
   *     <p>パネル1-2</p>
   *   </dd>
   * </dl>
   */
  $.fn.accordion = function(options) {

    var defaults = {
      'tabs': '.js-accordion-tab',
      'tabpanels': '.js-accordion-panel',
      'useRole': false,
      'openFirstChild': true,
      'multiselectable': true,
      'tabClass': 'is-active',
      'panelClass': 'is-active',
      'beforeSetting': false,
      'afterSetting': false,
      'beforeClick': false,
      'afterClick': false,
      'beforeKeyEvent': false,
      'afterKeyEvent': false
    };
    var settings = $.extend(defaults, options);

    return this.each(function(i) {
      var $this = $(this);

      /**
       * 初期設定：
       * 複数のタブがページ内にあることを想定して、一意なIDを付与する。
       */
      var accordionId = i + 1;
      while($('#accordion' + accordionId + '-1').length) {
        accordionId++;
      }

      /**
       * 初期設定：
       * オプションを変数化する。
       */
      var $tablist = $this;
      var $tabs = $tablist.find(settings['tabs']);
      var $tabpanels = $tablist.find(settings['tabpanels']);
      var useRole = settings['useRole'];
      var openFirstChild = settings['openFirstChild'];
      var multiselectable = settings['multiselectable'];
      var tabClass = settings['tabClass'];
      var panelClass = settings['panelClass'];
      var beforeSetting = settings['beforeSetting'];
      var afterSetting = settings['afterSetting'];
      var beforeClick = settings['beforeClick'];
      var afterClick = settings['afterClick'];
      var beforeKeyEvent = settings['beforeKeyEvent'];
      var afterKeyEvent = settings['afterKeyEvent'];

      if(beforeSetting !== false) {
        beforeSetting(this);
      }

      /**
       * 初期設定：
       * 各要素にrole属性を付与する。
       */
      var setRoleAttribute = (function() {
       if(useRole) {
         $tablist.attr('role', 'tablist');
         $tabs.attr('role', 'tab');
         $tabpanels.attr('role', 'tabpanel');
       }
      }());

      /**
       * 初期設定：
       * 複数のtabpanelを開く場合はaria-multiselectable属性を付与する。
       */
      if(multiselectable) {
        $tablist.attr('aria-multiselectable', 'true');
      }

      /**
       * 初期設定：
       * `$tabs`をフォーカス可能にする。
       */
      $tabs.attr('tabindex', '0');

      /**
       * 初期設定：
       * 各要素を紐付けるためのIDを付与する。
       * すでにIDが指定されていたら、それを使用する。
       */
      var associateTabWithTabpanel = (function() {
        if(!$tabs.eq(0).attr('id')) {
          $tabs.each(function(i) {
            var index = i + 1;
            $(this).attr({
              'id': 'accordion' + accordionId + '-' + index,
              'aria-controls': 'accordion-panel' + accordionId + '-' + index
            });
          });
        }
        if(!$tabpanels.eq(0).attr('id')) {
          $tabpanels.each(function(i) {
            var index = i + 1;
            $(this).attr({
              'aria-labelledby': 'accordion' + accordionId + '-' + index,
              'id': 'accordion-panel' + accordionId + '-' + index
            });
          });
        }
      }());

      /**
       * 初期設定：
       * タブをすべて非表示にする。
       */
      function hideAllTabs() {
        $tabs.attr('aria-expanded', 'false').removeClass(tabClass);
        $tabpanels.attr('aria-hidden', 'true').removeClass(panelClass);
      }
      hideAllTabs();

      /**
       * 初期設定：
       * 最初のタブを表示させる。
       */
      if(openFirstChild) {
        $tabs.eq(0).attr('aria-expanded', 'true').addClass(tabClass);
        $tabpanels.eq(0).attr('aria-hidden', 'false').addClass(panelClass);
      }

      if(afterSetting !== false) {
        afterSetting(this);
      }

      /**
       * タブがクリック・タップされたら、該当するタブを表示する。
       */
      var tabClick = (function() {
        $tabs.on('click', function(e) {
          var $thisTab = $(this);
          var controls = $thisTab.attr('aria-controls');

          if(beforeClick !== false) {
            beforeClick(event, this);
          }

          // 閉じているタブをクリックした場合
          if($thisTab.attr('aria-expanded') === 'false') {
            if(!multiselectable) {
              hideAllTabs();
            }
            $thisTab.attr('aria-expanded', 'true').addClass(tabClass);
            $tabpanels.each(function() {
              if($(this).attr('id') === controls) {
                $(this).attr('aria-hidden', 'false').addClass(panelClass);
              }
            });
          } else {
            // 開いているタブをクリックした場合
            if(!multiselectable) {
              hideAllTabs();
            }
            $thisTab.attr('aria-expanded', 'false').removeClass(tabClass);
            $tabpanels.each(function() {
              if($(this).attr('id') === controls) {
                $(this).attr('aria-hidden', 'true').removeClass(panelClass);
              }
            });
          }

          if(afterClick !== false) {
            afterClick(event, this);
          }

          e.preventDefault();
        });
       }());

      /**
       * キーボード操作。
       * 上下の矢印キーでフォーカスを動かす。上で戻り、下で進む。
       * フォーカスは行き止まりにならず、ループする。
       * enterかスペースを押したときも、クリックイベントと同様の処理をする。
       */
      var tabKeyEvent = (function() {
        $tabs.on('keydown', function(event) {
          var index = $tabs.index(this);
          var key = event.which;
          var keys = {
              up: 38,
              down: 40,
              enter: 13,
              space: 32
            };

          if(beforeKeyEvent !== false) {
            beforeKeyEvent(event, this);
          }

          if(key === keys.up){
            index--;
          } else if(key === keys.down){
            index++;
            // 最後のタブまで来たら最初のタブに戻る。
            if(index === $tabs.length) {
              index = 0;
            }
          }
          // 上下の矢印キー。
          if(key === keys.up || key === keys.down) {
            $tabs.get(index).focus();
          }
          // enterかスペースキー。
          if(key === keys.enter || key === keys.space) {
            $(this).click();
            $(this).focus();
            event.preventDefault();
          }

          if(afterKeyEvent !== false) {
            afterKeyEvent(event, this);
          }
        });
      }());

    });
  };
})(jQuery);
